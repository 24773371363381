import { __decorate, __extends } from "tslib";
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/dist/env/hosts';
import { pauseVideo } from 'widget/redux/client/actions/player/change-playback-status';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getVideosGroupedByIds } from 'shared/selectors/videos';
import { getWidgetVideoCards } from 'widget/selectors/video-cards';
import { VIDEO_CARD_TYPES } from '@wix/wix-vod-constants/dist/video-cards/types';
import videoCardsBiLogger from 'widget/player-widget/components/new-player/bi-loggers/video-cards-bi-logger';
var logCardClick = videoCardsBiLogger.logCardClick, logCardCloseButtonClick = videoCardsBiLogger.logCardCloseButtonClick;
var isVideoCard = function (card) {
    return card.type === VIDEO_CARD_TYPES.VIDEO ||
        card.type === VIDEO_CARD_TYPES.RECENT_VIDEO;
};
var isCustomContentCard = function (card) {
    return card.type === VIDEO_CARD_TYPES.PRODUCT ||
        card.type === VIDEO_CARD_TYPES.CUSTOM;
};
var DEFAULT_THUMBNAIL_SIZE = 110;
var VideoCardsWrapper = /** @class */ (function (_super) {
    __extends(VideoCardsWrapper, _super);
    function VideoCardsWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.logCardClose = function (_a) {
            var id = _a.id;
            _this.props.logCardCloseButtonClick({
                card: _.find(_this.props.cardsData, { clientId: id }),
            });
        };
        _this.handleCardClick = function (card) {
            var _a = _this.props, channelData = _a.channelData, pauseVideo = _a.pauseVideo, biOrigin = _a.biOrigin, handlePlayRequest = _a.handlePlayRequest;
            var actionListId = card.actionListId, actionItemId = card.actionItemId;
            _this.props.logCardClick({ card: card, biOrigin: biOrigin });
            if (isVideoCard(card) && channelData.id === actionListId) {
                handlePlayRequest({ id: actionItemId });
                return;
            }
            if (isCustomContentCard(card) && !card.url) {
                return;
            }
            pauseVideo();
        };
        return _this;
    }
    VideoCardsWrapper.prototype.componentDidMount = function () {
        var player = this.props.player;
        if (player && _.isFunction(player.onCardClose)) {
            player.onCardClose(this.logCardClose);
        }
    };
    VideoCardsWrapper.prototype.render = function () {
        var _a = this.props, cardsData = _a.cardsData, children = _a.children;
        return children({
            cardsData: cardsData,
            onCardClick: this.handleCardClick,
        });
    };
    VideoCardsWrapper.propTypes = {
        handlePlayRequest: PropTypes.func.isRequired,
        channelData: PropTypes.object,
        videoByIds: PropTypes.object,
        cardsData: PropTypes.array,
        player: PropTypes.object,
        children: PropTypes.func.isRequired,
        pauseVideo: PropTypes.func,
        thumbnailSize: PropTypes.number,
        biOrigin: PropTypes.string,
    };
    VideoCardsWrapper.defaultProps = {
        thumbnailSize: DEFAULT_THUMBNAIL_SIZE,
    };
    VideoCardsWrapper = __decorate([
        connect(function (state, _a) {
            var thumbnailSize = _a.thumbnailSize;
            return ({
                cardsData: getWidgetVideoCards({
                    thumbnailSize: thumbnailSize,
                    imageHost: PRODUCTION_HOSTS.image,
                })(state),
                videoByIds: getVideosGroupedByIds(state),
                channelData: getChannelForWidget(state),
            });
        }, {
            pauseVideo: pauseVideo,
            logCardClick: logCardClick,
            logCardCloseButtonClick: logCardCloseButtonClick,
        })
    ], VideoCardsWrapper);
    return VideoCardsWrapper;
}(React.PureComponent));
export default VideoCardsWrapper;
