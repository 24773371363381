import { ItemType } from '@wix/wix-vod-constants/dist/api-types/enums/item-type';
import { BI_VIDEO_TYPE } from '@wix/wix-vod-shared/dist/src/common/constants/bi';
import { isLiveVideo } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/video-origin';
import { isVideoOverQuota } from '@wix/wix-vod-shared/dist/src/common/ui-selectors/video/quota';
import { convertSToMS } from '@wix/wix-vod-shared/dist/src/widget/utils/time';
import { logBi } from 'shared/worker/actions/bi';
import fedopsLogger from 'loggers/fedops/new-fedops-logger';
import { isAutoplayed } from 'shared/selectors/player';
var getVideoBISource = function (videoItem) {
    if (isLiveVideo(videoItem)) {
        switch (videoItem.itemType) {
            case ItemType.ItemScheduled:
                return BI_VIDEO_TYPE.SCHEDULED;
            case ItemType.ItemLive:
                return BI_VIDEO_TYPE.LIVE;
            default:
                break;
        }
    }
    return videoItem.videoSource;
};
export var logWidgetVidClick = function (_a) {
    var videoItem = _a.videoItem, channelData = _a.channelData, _b = _a.buttonName, buttonName = _b === void 0 ? 'play_video' : _b;
    return function (dispatch) {
        var id = videoItem.id, durationSec = videoItem.durationSec;
        var videoBiSource = getVideoBISource(videoItem);
        dispatch(logBi('widget.vid.clicked', {
            duration: convertSToMS(durationSec),
            channelID: channelData.id,
            videoType: videoBiSource,
            videoID: id,
            overQuoted: isVideoOverQuota(videoItem),
            buttonName: buttonName,
        }));
        dispatch(fedopsLogger.playVideo({ videoSource: videoBiSource }).start());
    };
};
export var logWidgetVidPlayStart = function (_a) {
    var channelData = _a.channelData, videoItem = _a.videoItem, button = _a.button;
    return function (dispatch, getState) {
        var isAutoplay = isAutoplayed(getState());
        var id = videoItem.id, durationSec = videoItem.durationSec;
        var videoBiSource = getVideoBISource(videoItem);
        dispatch(logBi('widget.vid.start.play', {
            duration: convertSToMS(durationSec),
            channelID: channelData.id,
            videoType: videoBiSource,
            videoID: id,
            isAutoplay: isAutoplay,
            button: button,
        }));
        if (!isAutoplay) {
            dispatch(fedopsLogger.playVideo({ videoSource: videoBiSource }).end());
        }
    };
};
